import React from "react";
import "./App.css";
import Nav from "./components/Nav/Nav";
import ReactGA from "react-ga";

import { useRoutes } from "hookrouter";
import Routes from "./services/routes";
import { BrowserRouter } from "react-router-dom";
import ScrollHandler from "./components/ScrollHandler/ScrollHandler";
import Home from "./components/Home/Home";

function App() {
  // const routeResult = useRoutes(Routes);
  // return routeResult;
  return (
    <BrowserRouter>
      <ScrollHandler />
      <Home />
    </BrowserRouter>
  );
}

// function initializeReactGA() {
//   ReactGA.initialize("UA-179259905-1");
//   ReactGA.pageview("/homepage");
// }

export default App;
