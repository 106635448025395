import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./SendMessageRow.css";

// import requestIcon from "../../resources/kbd-request.svg";

// import Nav from "../Nav/Nav";
// import Footer from "../Footer/Footer";

// import LottieAnimation from "../LottieAnimation/LottieAnimation";
// import ServicesLottie from "../../resources/lotties/services-lottie.json";

// import prototypeIcon from "../../resources/kbd-prototype.svg";
// import devIcon from "../../resources/kbd-code-website.svg";
// import featureIcon from "../../resources/kbd-feature.svg";
// import cloudIcon from "../../resources/kbd-cloud.svg";
// import seoIcon from "../../resources/kbd-www.svg";
// import maintenanceIcon from "../../resources/kbd-maintenence-icon.png";

function SendMessageRow(props) {
  return (
    <React.Fragment>
      <div className="services-contact-us">
        <div className="row">
          <div className="col-lg-6 services-contact-text-col">
            {/* <img src={requestIcon} className="services-contact-request-icon" /> */}
            <span className="services-contact-us-text">
              Have any requests or inquiries? We want to make something that is
              perfect for you! Send us a short message about what you want, and
              we will make it happen.
            </span>
          </div>
          <div className="col-lg-6 services-contact-btn-col">
            <a href="/contact" className="services-contact-btn">
              <span className="services-contact-btn-text">Contact Us</span>
              <i class="fa fa-angle-right services-contact-btn-arrow"></i>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SendMessageRow;
