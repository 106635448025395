import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";

// import FA from "react-fontawesome";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";

/* ---------- images ---------- */
// import contactImg from "../../resources/email.svg";

/* ---------- lottie animations ---------- */
import loadingWheelLottie from "../../resources/lotties/loading-wheel.json";
import emailLottie from "../../resources/lotties/email-services.json";

/* ---------- components ---------- */
// import Nav from "../Nav/Nav";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import { TextField } from "@material-ui/core";

const Contact = forwardRef((props, ref) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [showSendingAlert, setShowSendingAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const defaultErrorMessage = "Something went wrong...";
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  useEffect(() => {
    setShowFailAlert(false);
  }, [name]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [email]);

  const handleSubmit = () => {
    const templateId = "kbd_contact_form";

    let validEmail = checkEmail(email);
    let validName = checkValid(name);
    let validPhoneNum = checkPhone(phoneNumber);

    if (validEmail && validName && validPhoneNum) {
      setShowFailAlert(false);
      setErrorMessage(defaultErrorMessage);
      sendFeedback(templateId, {
        user_message: message + "\nPhone Number: " + phoneNumber,
        user_name: name,
        user_email: email,
        client_email: "acefitnessmedia@gmail.com",
        client_name: "Ace",
      });

      setName("");
      setEmail("");
      setMessage("");
      setPhoneNumber("");
    } else {
      console.log("setting error message?");

      if (!validEmail) {
        setErrorMessage("Invalid Email Address");
      }
      if (!validPhoneNum) {
        setErrorMessage("Invalid Phone Number");
      }
      if (!validName) {
        setErrorMessage("Name cannot be empty");
      }

      console.log("showing fail alert");
      setShowFailAlert(true);
    }
  };

  const checkEmail = (email) => {
    console.log(email);
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const checkValid = (message) => {
    if (message == "" || message == null || message == undefined) {
      return false;
    } else {
      return true;
    }
  };

  const checkPhone = (input) => {
    var currNumLength = input.replace(/[^0-9]/g, "").length;
    return currNumLength === 10;
  };

  const sendFeedback = (templateId, variables) => {
    setShowSendingAlert(true);
    window.emailjs
      .send("cs_gmail", templateId, variables)
      .then((res) => {
        console.log("Email successfully sent!");
        setShowSuccessAlert(true);
        setShowSendingAlert(false);
        setTimeout(function () {
          setShowSuccessAlert(false);
        }, 3000);
      })
      .catch((err) => {
        setShowFailAlert(true);
        setTimeout(function () {
          setShowFailAlert(false);
        }, 3000);
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  };

  const handlePhoneNumberChange = (newPhoneNumber) => {
    var tempNewPhoneNumber = newPhoneNumber;
    var tempPhoneNumber = phoneNumber;
    var newNumLength = tempNewPhoneNumber.replace(/[^0-9]/g, "").length;
    var currNumLength = tempPhoneNumber.replace(/[^0-9]/g, "").length;

    var regExAlphabet = /[a-zA-Z]/g;
    var regExSpecial = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;

    tempNewPhoneNumber = tempNewPhoneNumber.replace("(", "");
    tempNewPhoneNumber = tempNewPhoneNumber.replace(") ", "");
    tempNewPhoneNumber = tempNewPhoneNumber.replace("-", "");

    if (
      regExAlphabet.test(tempNewPhoneNumber) ||
      regExSpecial.test(tempNewPhoneNumber) ||
      newNumLength > 10
    ) {
      return;
    }
    setShowFailAlert(false);

    if (newNumLength >= 3 && !newPhoneNumber.includes("(")) {
      newPhoneNumber =
        "(" + newPhoneNumber.slice(0, 3) + ") " + newPhoneNumber.slice(3);
    }

    if (
      newPhoneNumber[newPhoneNumber.length - 1] == ")" &&
      newPhoneNumber.length < phoneNumber.length
    ) {
      newPhoneNumber = newPhoneNumber.substring(1, newPhoneNumber.length - 2);
    }

    if (newNumLength >= 7 && !newPhoneNumber.includes("-")) {
      newPhoneNumber =
        newPhoneNumber.slice(0, 9) + "-" + newPhoneNumber.slice(9);
    }

    if (newPhoneNumber[newPhoneNumber.length - 1] == "-") {
      newPhoneNumber = newPhoneNumber.substring(0, newPhoneNumber.length - 1);
    }
    setPhoneNumber(newPhoneNumber);
  };

  return (
    <React.Fragment>
      <div className="contact-background">
        <div className="container contact-container">
          <div className="content-wrapper contact-content-wrapper">
            <LottieAnimation src={emailLottie} width={150} height={150} />
            <h1 className="contact-header">CONTACT</h1>
            <h2 className="contact-info">
              Please reach out for any questions you might have, or if you'd
              like to leave a review!
            </h2>

            <div className="container form-container text-left">
              <form>
                <div className="form-group">
                  <label htmlFor="">Message</label>
                  <textarea
                    name=""
                    id=""
                    className="form-control form-message"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Phone Number</label>
                  {/* <TextField
                    id="phone"
                    className="form-control"
                    label="Phone Num"
                    inputProps={{ inputMode: "numeric" }}
                  /> */}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Phone Number"
                    inputProps={{ inputMode: "numeric" }}
                    pattern="[0-9]*"
                    value={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="your-email@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                {showSuccessAlert && (
                  <div className="alert success-alert">
                    <p>Email successfully sent!</p>
                  </div>
                )}

                {showSendingAlert && (
                  <div className="alert sending-alert">
                    <p>Sending Email</p>
                    <div className="lottie-container">
                      <LottieAnimation
                        src={loadingWheelLottie}
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>
                )}

                {showFailAlert && (
                  <div className="alert fail-alert">
                    <p>{errorMessage}</p>
                  </div>
                )}

                <input
                  type="button"
                  value="Submit"
                  className="contact-submit-btn"
                  onClick={handleSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </React.Fragment>
  );
});

Contact.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  description: PropTypes.string,
};

Contact.defaultProps = {
  value: 0,
  description: "",
};

export default Contact;
