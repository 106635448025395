import React, { useState, useEffect, useRef } from "react";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
// import "../LottieAnimation/node_modules/bootstrap/dist/css/bootstrap.css";
import "./Home.css";

/* ---------- images ---------- */

/* ---------- components ---------- */
import Contact from "../Contact/Contact";
import Reviews from "../Reviews/Reviews";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ImageCarouselHeader from "../ImageCarouselHeader/ImageCarouselHeader";
import SendMessageRow from "../SendMessageRow/SendMessageRow";
import ScrollHandler from "../ScrollHandler/ScrollHandler";

import donovanPic from "../../resources/donovan-pic.jpg";
import workImg from "../../resources/portfolio.png";
import logo from "../../resources/logo.png"
import dumbbellImg from "../../resources/dumbbell.png";
import medalImg from "../../resources/medal.png";
import shieldImg from "../../resources/shield.png";
import { BrowserRouter } from "react-router-dom";

const Home = (props) => {
  const childReference = useRef(null);

  const scrollIntoView = () => {
    childReference.current.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth",
    });
  };
  return (
    <React.Fragment>
      {/* <ScrollHandler /> */}

      <Nav />

      <ImageCarouselHeader />


      <div className="home-container uk-animation-fade">
        <div className="logo-container">
        <img src={logo} alt="" className="home-logo" />
        </div>
        <div className="home-details">
          Choose from a wide range of specialized educational courses and
          certifications. You will enjoy a rewarding and personally fulfilling
          career in fields such as: fitness, nutrition, lifestyle consulting,
          personal training, sports conditioning, personal development or
          coaching. We will equip you with the tools you need to succeed.
        </div>
        <div className="careers-container">
          {/* START RULE OF 3 */}

          <div className="position-relative overflow-hidden p-3  text-center text-light">
            <div className="row">
              <div className="col-lg-4 services-container">
                <div className="category-img-container">
                  <img
                    src={dumbbellImg}
                    className="category-img"
                    alt="category-img"
                  />
                </div>
                <h2 className="about-job-header">Study Up</h2>
                <p className="about-description">
                  Learn about fitness from all angles: nutrition, personal
                  training, sports conditioning, and more.
                </p>
              </div>
              <div className="col-lg-4 services-container">
                <div className="category-img-container">
                  <img src={medalImg} className="category-img" />
                </div>
                <h2 className="about-job-header">Get Certified</h2>
                <p className="about-description">
                  When you're ready, take our comprehensive exam and earn your
                  FITEX certification.
                </p>
              </div>
              <div className="col-lg-4 services-container">
                <div className="category-img-container">
                  <img src={workImg} className="category-img" />
                </div>
                <h2 className="about-job-header">Become a Professional</h2>
                <p className="about-description">
                  Once you're certified, take your fitness career in the
                  direction you want.
                </p>
              </div>
            </div>
          </div>

          <h2 className="reviews-title">REVIEWS</h2>
          <Reviews />
        </div>
      </div>

      <div id="#contact-section"></div>
      <Contact />

      <Footer />
    </React.Fragment>
  );
};

export default Home;
