import React, { useState } from "react";
import "./Nav.css";
import "./hamburgers.css";
import logo from "../../resources/logo.png";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log(pageURI);
  };

  const responsive = menuOpen ? " responsive" : "";

  const borderRadius = menuOpen ? " topnav-border-radius" : "";

  const hamburgerType = menuOpen ? " is-active" : "";

  const pageURI = window.location.pathname + window.location.search;

  return (
    <React.Fragment>
      <div className={"topnav" + responsive} id="myTopnav">
        <div className="top-strip">
          <span>
            {" "}
            <a href="tel:702-858-1168" className="top-strip-number">
              {" "}
              702-401-2237
            </a>
          </span>
          {/* <a href="https://www.facebook.com/ACEFitnessLV/" target="_blank">
            <i className="fa fa-facebook top-strip-icon"></i>
          </a> */}
          <a
            href="https://instagram.com/fitexnow?r=nametag"
            target="_blank"
          >
            <i className="fa fa-instagram top-strip-icon"></i>
          </a>
          {/* <a
            href="https://twitter.com/ACEFitnessLV?fbclid=IwAR1dYveSjwE0hZULBlPQrbpcwxpBKs0JpdEsh6ZZsmb9Mo6A_IPp2gOA6uc"
            target="_blank"
          >
            <i className="fa fa-twitter top-strip-icon"></i>
          </a> */}
        </div>
        <a href="/" className="top-nav-brand topnav-item topnav-first">
          {/* <img className="topnav-logo" src={logo} alt="" /> */}
          <span className="topnav-company-name">FITEX</span>
        </a>
        {/* <a href="/" target="_blank">
          <i className="fa fa-facebook mobile-nav-item"></i>
        </a> */}
        <a href="https://instagram.com/fitexnow?r=nametag" target="_blank">
          <i className="fa fa-instagram mobile-nav-item"></i>
        </a>
        {/* <a href="/" target="_blank">
          <i className="fa fa-linkedin mobile-nav-item"></i>
        </a> */}

        <button
          className={
            " hamburger hamburger--spin no-bs-border hamburger-icon" +
            hamburgerType
          }
          type="button"
          onClick={toggleMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Nav;
