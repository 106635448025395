import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./ImageCarouselHeader.css";
import { Link } from "react-router-dom";

// import Nav from "../Nav/Nav";
// import Footer from "../Footer/Footer";
// import devicesImage from "../../resources/devices-fix.png";
// import designIcon from "../../resources/kbd-website-icon.png";
// import pinkVestImage from "../../resources/pink-vest.jpg";
// import customTailorImage from "../../resources/custom-tailor.jpeg";
// import tuxImage from "../../resources/category-3.jpeg";

// import hostingIcon from "../../resources/kbd-hosting-icon.png";
// import maintenanceIcon from "../../resources/kbd-maintenence-icon.png";
// import Link from "react";

function ImageCarouselHeader(props) {
  return (
    <header className="carousel-header">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators z-2">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>

        <div className="slogan">
          <h3 className="slogan-text">
            FITEX is the Association for Entrepreneurial Fitness Experts &
            Coaches
          </h3>
          <Link to="/#contact-section" className="get-in-touch-btn">
            GET CERTIFIED
          </Link>
        </div>

        <div className="carousel-inner" role="listbox">
          {/* Slide One  */}
          <div className="carousel-item active item-1"></div>
          {/* Slide Two  */}
          <div className="carousel-item item-2"></div>
          {/* Slide Three  */}
          <div className="carousel-item item-3"></div>
        </div>
        <a
          className="carousel-control-prev z-2"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next z-2"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </header>
  );
}

export default ImageCarouselHeader;
